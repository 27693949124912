import { css } from "@emotion/core";
import { useIntl } from "gatsby-plugin-intl";
import React, { useState } from "react";
import { mainColor } from "../../constants/colors";
import { Desktop, Tablet } from "../../constants/responsive";
import Button from "../Button";

const CheckCircle = ({ active, setActive, text }) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        font-size: 12px;
        margin-top: 12px;
        cursor: pointer;
        user-select: none;
        ${Desktop} {
          font-size: 14px;
        }
      `}
      onClick={() => {
        setActive(!active);
      }}
    >
      <div
        css={css`
          width: 20px;
          height: 20px;
          border-radius: 12px;
          border: 4px solid #c4c4c4;
          transition: all 0.2s ease;
          background: ${active ? mainColor : "#c4c4c4"};
          margin-right: 10px;

          ${Desktop} {
            width: 20px;
            height: 20px;
            border-radius: 16px;
            border: 4px solid #c4c4c4;
          }
        `}
      ></div>
      {text}
    </div>
  );
};

const ProfessionalInvestorsDisclaimer = ({ useShowDisclaimer }) => {
  const intl = useIntl();
  const [show, setShow] = useShowDisclaimer();
  const [professional, setProfessional] = useState(false);
  const [understand, setUnderstand] = useState(false);
  return (
    <>
      {show && (
        <div
          css={css`
            position: fixed;
            z-index: 9999;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.6);
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px;
          `}
        >
          <div
            css={css`
              border-radius: 20px;
              background: white;
              display: flex;
              flex-direction: column;
              max-width: 600px;
              padding: 16px;
              font-size: 12px;
              @media (max-width: 400px) {
                font-size: 10px;
                line-height: 14px;
              }
              line-height: 16px;
              position: relative;
              ${Tablet} {
                padding: 30px;
                font-size: 14px;
                line-height: 20px;
              }
            `}
          >
            <div
              css={css`
                text-align: left;
              `}
            >
              <b>
                {
                  {
                    en: "Disclaimer",
                    "zh-cn": "风险声明",
                    "zh-hk": "風險聲明",
                  }[intl.locale]
                }
              </b>
              <br />
              {
                {
                  en: (
                    <div>
                      Investment involves risks. The fund does not give any
                      guarantees. Past performance is not a reliable indicator
                      of its future performance. AQUMON does not guarantee the
                      performance of any AQUMON funds, the return of an
                      investor’s capital nor any specific rate of return. Value
                      of investments can go down as well as up. Prior to
                      investing, please refer to the Explanatory Memorandum of
                      the fund for details including risk factors. You should
                      ensure you fully understand the risks associated with the
                      investment and should also consider your own investment
                      objective and risk tolerance. If you have any queries,
                      please contact your financial advisor and seek
                      professional advice.
                      <br />
                      <br />
                      As investment in the funds often involves investing in
                      international markets. In addition to the normal risks
                      associated with investing, international investments
                      involve the risk of losing all or part of any capital from
                      unfavourable fluctuations in currency values, from
                      differences in generally accepted accounting principles or
                      from economic or political instability in other nations.
                      An investment in emerging markets also involves heightened
                      other risks such as increased volatility and the
                      possibility of lower trading volume.
                    </div>
                  ),
                  "zh-cn": (
                    <div>
                      投资基金涉及风险，本基金并不提供任何保证。过往表现并不为将来表现的可靠指标，我司并不保证任何基金的表现、投资者本金回报及任何回报率。投资价值可升可跌。建议阁下投资前请参阅基金说明书，了解风险因素等资料。阁下应确保完全理解基金所附带的风险，并应考虑自身的投资目标及可承受的风险水平。阁下如有疑问，谨请与阁下的财务顾问联络及咨询专业意见。
                      <br />
                      <br />
                      投资有关基金经常涉及投资国际市场。除一般投资风险外，国际投资亦会因货币价值的不利波动，公认会计原则的差异或其他国家的经济或政治局势动荡而承受损失全部或任何部分资金的风险。投资新兴市场亦会增加其他风险，例如波动的幅度有可能上升以及交投可能会处于平淡期。
                    </div>
                  ),
                  "zh-hk": (
                    <div>
                      投資基金涉及風險，本基金並不提供任何保證。過往表現並不為將來表現的可靠指標，我司並不保證任何基金的表現、投資者本金回報及任何回報率。投資價值可升可跌。建議閣下投資前請參閱基金說明書，了解風險因素等資料。閣下應確保完全理解基金所附帶的風險，並應考慮自身的投資目標及可承受的風險水平。閣下如有疑問，謹請與閣下的財務顧問聯絡及諮詢專業意見。
                      <br />
                      <br />
                      投資有關基金經常涉及投資國際市場。除一般投資風險外，國際投資亦會因貨幣價值的不利波動，公認會計原則的差異或其他國家的經濟或政治局勢動盪而承受損失全部或任何部分資金的風險。投資新興市場亦會增加其他風險，例如波動的幅度有可能上升以及交投可能會處於平淡期。
                    </div>
                  ),
                }[intl.locale]
              }
            </div>
            <CheckCircle
              active={professional}
              setActive={setProfessional}
              text={
                {
                  en: "I am a professional investor.",
                  "zh-cn": "我是一名专业投资者。",
                  "zh-hk": "我是一名專業投資者。",
                }[intl.locale]
              }
            />
            <CheckCircle
              active={understand}
              setActive={setUnderstand}
              text={
                {
                  en: "I understand and know the above risks disclosure.",
                  "zh-cn": "我了解并知晓以上投资风险。",
                  "zh-hk": "我了解並知悉以上投資風險。",
                }[intl.locale]
              }
            />
            <div
              css={css`
                display: flex;
                justify-content: center;
                margin-top: 20px;
              `}
            >
              <Button
                disabled={!professional || !understand}
                onClick={() => {
                  setShow(false);
                }}
              >
                {
                  {
                    en: "Confirm",
                    "zh-cn": "确认",
                    "zh-hk": "確認",
                  }[intl.locale]
                }
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfessionalInvestorsDisclaimer;
