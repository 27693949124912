import React from "react";
import { css } from "@emotion/core";
import { motion, useAnimation } from "framer-motion";
import { useMount } from "react-use";
import { Desktop, Tablet } from "../../constants/responsive";

const Stripe = ({ top, left, height, blue = false, index = 0 }) => {
  const controls = useAnimation();
  const width = 6;
  const bgSize = 100;
  const xOffset = left;
  const yOffset = top;
  const bgPercentX = (bgSize / width) * 100;
  const bgPercentY = (bgSize / height) * 100;
  const bgX = (100 * xOffset) / width;
  const bgY = (100 * yOffset) / height;

  useMount(async () => {
    controls.start({ opacity: 1, transition: { delay: 0.1 * index } });
    await controls.start({
      y: [`0%`, `-${300 * (6 / height)}%`],
      transition: {
        repeat: Infinity,
        repeatType: "mirror",
        duration: 5,
        delay: 0.3 * index,
      },
    });
  });

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={controls}
      css={css`
        position: absolute;
        top: ${top}%;
        left: ${left}%;
        width: ${width}%;
        height: ${height}%;
        background: green;
        opacity: 0;
        border-radius: 48px;
        overflow: hidden;
        will-change: transform;
        z-index: 0;
      `}
    >
      <div
        css={css`
          background-image: ${!blue
            ? "linear-gradient(40deg, #ff80ab 20%, #ee2f79)"
            : "linear-gradient(40deg, #c3e7f9 40%, #1ca3dd)"};
          position: absolute;
          top: -${bgY}%;
          left: -${bgX}%;
          width: ${bgPercentX}%;
          height: ${bgPercentY}%;
        `}
      />
    </motion.div>
  );
};

const ProfessionalInvestorsAnimation = () => {
  return (
    <div
      css={css`
        position: absolute;
        top: -6px;
        left: 140px;
        width: 220px;
        height: 220px;
        ${Tablet} {
          left: 50%;
          width: 312px;
          height: 312px;
          transform: translate(100px, 90px);
        }
        ${Desktop} {
          width: 480px;
          height: 480px;
          transform: translate(100px, 150px);
        }
      `}
    >
      <Stripe top={21} left={0} height={6} blue index={0} />
      <Stripe top={31} left={0} height={36} blue index={0} />

      <Stripe top={45} left={11} height={29} index={1} />

      <Stripe top={32} left={22} height={35} blue index={2} />

      <Stripe top={18} left={34} height={28} blue index={3} />

      <Stripe top={32} left={45} height={36} index={4} />
      <Stripe top={70} left={45} height={6} index={4} />

      <Stripe top={50} left={58} height={36} index={5} />

      <Stripe top={12} left={70} height={47} blue index={6} />

      <Stripe toe={0} left={82} height={6} blue index={7} />
      <Stripe top={9} left={82} height={29} blue index={7} />

      <Stripe top={18} left={94} height={26} index={8} />
    </div>
  );
};

export default ProfessionalInvestorsAnimation;
