import React from "react";
import Container from "../Container";
import BannerBand from "../../svgs/banner-band.svg";
import Check from "../../svgs/professional-investors/check.svg";
import { Desktop, Tablet } from "../../constants/responsive";
import { css } from "@emotion/core";
import { FormattedHTMLMessage } from "gatsby-plugin-intl";
import ProfessionalInvestorsAnimation from "../ProfessionalInvestorsAnimation";

const ProfessionalInvestorsBanner = ({ useShowDisclaimer }) => {
  const [show] = useShowDisclaimer();
  return (
    <>
      <div
        css={css`
          background: #14123c;
          width: 100%;
          height: 380px;
          display: flex;
          overflow: hidden;
          justify-content: center;
          position: relative;
          ${Tablet} {
            height: 425px;
          }
          ${Desktop} {
            height: 600px;
          }
          color: white;
        `}
      >
        {!show && <ProfessionalInvestorsAnimation />}
        <Container
          css={css`
            z-index: 1;
          `}
        >
          <div
            css={css`
              padding-top: 180px;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              flex-direction: column;
              ${Tablet} {
                padding-top: 90px;
              }
              ${Desktop} {
                padding-top: 180px;
              }
            `}
          >
            <h1
              css={css`
                ${Tablet} {
                  line-height: 50px;
                }
                ${Desktop} {
                  line-height: 65px;
                }
              `}
            >
              <FormattedHTMLMessage id="professional-investors.banner.title" />
            </h1>
            {[1, 2].map((i) => {
              return (
                <div
                  key={i}
                  css={css`
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                    width: 100%;
                  `}
                >
                  <Check
                    css={css`
                      flex: 0 0 20px;
                      margin-right: 12px;
                      ${Tablet} {
                        flex: 0 0 25px;
                      }
                      ${Desktop} {
                        margin-right: 20px;
                        flex: 0 0 30px;
                      }
                      * {
                        fill: white;
                      }
                    `}
                  />
                  <h3
                    css={css`
                      margin: 0px;
                      ${Tablet} {
                        width: 320px;
                      }
                      ${Desktop} {
                        width: 600px;
                      }
                    `}
                  >
                    <FormattedHTMLMessage
                      id={`professional-investors.banner.text-${i}`}
                    />
                  </h3>
                </div>
              );
            })}
          </div>
        </Container>
      </div>
      <div
        css={css`
          height: 25px;
          width: 100%;
          min-width: 320px;
          * {
            fill: #14123c;
          }
          ${Tablet} {
            height: 40px;
          }
          ${Desktop} {
            height: 85px;
          }
        `}
      >
        <BannerBand width="100%" height="100%" />
      </div>
    </>
  );
};

export default ProfessionalInvestorsBanner;
